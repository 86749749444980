import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { emptyDataImage } from '@fsd-shared/assets';
import { CardWrapper } from '@fsd-shared/ui-kit';

import { TEmptyData } from './EmptyData.types';

import './EmptyData.style.scss';
export const EmptyData: React.FC<TEmptyData> = (props) => {
    const { text, imageContent } = props;

    return (
        <CardWrapper additionalCls={'empty-data'}>
            {!!text && <AppText text={text} textStyle={'DesktopH2'} />}
            {imageContent ?? <img src={emptyDataImage} />}
        </CardWrapper>
    );
};
