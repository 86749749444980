import React from 'react';
import { IPagination } from '@fsd-shared/models/app';
import { Dayjs } from 'dayjs';

export type TSelectedMonth = {
    date: Dayjs;
    formattedDate: string;
    isCurrent: boolean;
};
export type TCriteriaType = 'questionsCreated' | 'questionApproval' | 'summary' | '';
export type TSelectedFilters = {
    criteriaType: TCriteriaType;
    showCriteriaContent: boolean;
    teachersIds?: string[];
    subjectsIds?: number[];
    isChartView: boolean;
};
export type TCriteria = TMonitoringProgress & {
    content: React.ReactNode;
};

export type TCriteriaListItem = {
    id: number;
    title: string;
    type: TCriteriaType;
    isActive: boolean;
    isComplete?: boolean;
};
export type TCriteriaGroup = {
    id: number;
    title: string;
    criteria: TCriteriaListItem[];
};
export type TTeachersProgressLevel = {
    label: string;
    value: number;
    bonus: number;
};
export type TTeacherStatistics = {
    completedCount: number;
    completedPercent: number;
    activeCount: number;
    activePercent: number;
    isCompleted: boolean;
    previousCount: number;
    level: number;
};
export type TTeacherProgress = {
    id: number;
    title: string;
    criteria: TMonitoringProgress[];
};
export type TTeachersMonitoringData = {
    progressLevels: TTeachersProgressLevel[];
    statistics: TTeacherStatistics;
    progress: TTeacherProgress[];
};
export type TTeachersProfileData = {
    criteriaGroups: TCriteriaGroup[];
};
export type TCriteriaSettingCommon = {
    isActive?: boolean;
    updateNext?: boolean;
};
export type TQuestionCreatedSettings = {
    dayEasy?: number;
    dayMedium?: number;
    dayHard?: number;
};
export type TCriteriaSetting = TCriteriaSettingCommon & TQuestionCreatedSettings;
export type TCriteriaSettingState = TCriteriaSetting & { isLoading: boolean; isSaving: boolean };
export type TPagination = {
    maxPage: number;
    currentPage: number;
    limit: number;
    count: number;
};
export type TMonitoringSubjectsList = {
    pagination?: TPagination;
    results?: TMonitoringSubject[];
    searchResults?: TMonitoringSubject[];
    search?: string;
};
export type TMonitoringSubject = {
    id: number;
    title: string;
};
export type TMonitoringStaffList = {
    pagination?: TPagination;
    results?: TMonitoringStaff[];
    searchResults?: TMonitoringStaff[];
    search?: string;
    subjects?: number[];
};
export type TMonitoringStaff = {
    id: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    photoUrl: string;
};
//ACTIONS
export type TGetCriteriaGroupsProps = {
    date?: string;
    isActive?: boolean;
};
export type TGetMonitoringSubjectsListProps = {
    search?: string;
    currentPage?: number;
    limit?: number;
};
export type TGetMonitoringStaffListProps = {
    roles?: string[];
    subjects?: number[];
    ids?: string[];
    search?: string;
    currentPage?: number;
    limit?: number;
};
export type TGetCriteriaSettingProps = {
    criterion: TCriteriaType;
    date: string;
    update?: TCriteriaSetting;
};

export type TMonitoringProgress = {
    label: string;
    value: string;
    isCompleted: boolean;
};
export type TSummaryTableDataItem = {
    user: TMonitoringStaff;
    progress: TMonitoringProgress[];
};

export type QuestionsCreatedCriterionTableProgress = {
    minEasy: number;
    minMedium: number;
    minHard: number;
    currentEasy: number;
    currentMedium: number;
    currentHard: number;
};
export type TQuestionsCreatedCriterionTableDataItem = {
    user: TMonitoringStaff;
    progress: QuestionsCreatedCriterionTableProgress;
};
export type TReason = 'medical_certificate' | 'not_month_start' | 'teacher_vacation' | 'other';
export type TApplyingProps = {
    value: number;
    changeReason: TReason;
    comment?: string;
};
export enum EReasonsTitles {
    'medical_certificate' = 'Больничный лист',
    'not_month_start' = 'Работа не с начала месяца',
    'teacher_vacation' = 'Отпуск',
    'other' = 'Другое',
}
export type TSummaryTableData = {
    results?: TSummaryTableDataItem[];
    pagination?: IPagination;
};
export type TTeacherDetailedInfoProgress = {
    id: number;
    title: string;
    criteria: TMonitoringProgress[];
};
export type TTeacherInfo = TMonitoringStaff & { subjects: TMonitoringSubject[] };
export type TTeachersWorkDaysRes = { user: string } & TTeachersWorkDays;
export type TTeachersCriteriaStatistic = {
    user: TTeacherInfo;
    progress: TTeacherProgress[];
};
export type TTeachersWorkDaysValue = {
    month: number;
    teacher: number;
};
export type TTeacherQuestionsCountValue = {
    current: number;
    month: number;
};
export type TChangeHistory = {
    date: string;
    oldValue: number;
    newValue: number;
    changeReason: string;
    comment: string;
    author: {
        id: string;
        lastName: string;
        firstName: string;
        patronymic: string;
    };
};

export type TTeachersWorkDays = {
    workDays?: TTeachersWorkDaysValue;
    workDaysHistory?: TChangeHistory[];
};
export type TQuestionsCreatedProgressInfo = {
    questionsCount?: TTeacherQuestionsCountValue;
    questionsCountHistory?: TChangeHistory[];
};
export type TQuestionsCreatedProgress = {
    user: string;
} & TQuestionsCreatedProgressInfo;
export type TSummaryChartDataPoint = {
    label: string;
    value: {
        current: number;
        max: number;
    };
};
export type TSummaryChartDataItem = {
    label: string;
    info: string;
    color: string;
    points: TSummaryChartDataPoint[];
};
export type TDiagram = {
    type: string;
    titleX: string;
    titleY: string;
};

export type TSummaryChartData = {
    diagram: TDiagram & {
        data: TSummaryChartDataItem[];
    };
};
export type TSummaryTableDataFilters = {
    date: string;
    subjects?: number[];
    users?: string[];
    completed?: string[];
    failed?: string[];
};
export type TGetSummaryTableDataProps = {
    filters: TSummaryTableDataFilters;
    currentPage?: number;
    limit?: number;
};
export type TGetTeachersCriteriaStatisticProps = {
    user: string;
    date?: string;
};

export type TGetTeachesWorkDaysProps = {
    user: string;
    date?: string;
    workDays?: {
        value: number;
        changeReason: string;
        comment?: string;
    };
};
export type TGetQuestionsCreatedProgressProps = {
    user: string;
    date?: string;
    questionsCount?: {
        value: number;
        changeReason: string;
        comment?: string;
    };
};
export type TQuestionsCreatedTableDataFilters = {
    date: string;
    subjects?: number[];
    users?: string[];
    isCompleted?: boolean;
};
export type TQuestionsCreatedTableDataProps = {
    filters: TQuestionsCreatedTableDataFilters;
    currentPage?: number;
    limit?: number;
};
export type TQuestionsCreatedDiagramDataFilters = {
    months: string[];
    subjects?: number[];
    users?: string[];
};

export type TSummaryChartDataFilters = {
    months: string[];
    subjects?: number[];
    users?: string[];
};
export type TQuestionsCreatedTableData = {
    results?: TQuestionsCreatedCriterionTableDataItem[];
    pagination?: TPagination;
};

export type TQuestionsCreatedDiagramTableItem = {
    month: string;
    minEasy: number;
    minMedium: number;
    minHard: number;
    currentEasy: number;
    currentMedium: number;
    currentHard: number;
};
export type TQuestionsCreatedDiagramItem = {
    label: string;
    info: string;
    color: string;
    points: {
        label: string;
        value: number;
    }[];
};
export type TTeachersQuestionsCreatedDiagramData = TDiagram & {
    data: TQuestionsCreatedDiagramItem[];
};
export type TQuestionsCreatedDiagramData = {
    table: TQuestionsCreatedDiagramTableItem[];
    diagram: TTeachersQuestionsCreatedDiagramData;
};
export type TQuestionApprovalData = {
    date: string;
    criteria: TQuestionsCreatedProgress & {
        approved: number;
        approvedTotal: number;
    };
};
export type TTeachersQuestionsCreatedDiagram = {
    diagramProgress: TTeachersQuestionsCreatedDiagramData;
    diagramMin: TTeachersQuestionsCreatedDiagramData;
};
