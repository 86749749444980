import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ssoLogin, ssoLogout } from 'api/services/token';
import { TLogin } from 'types/auth';

export const fetchLogin = createAsyncThunk('login/fetchLogin', async (values: TLogin) => {
    return await ssoLogin(values);
});

export const fetchLogout = createAsyncThunk('logout/fetchLogout', async () => {
    return await ssoLogout();
});

export const setIsLoggedInAction = createAction('login/setTokens', (payload: boolean) => {
    return { payload };
});
