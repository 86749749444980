import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { TCriteriaGroup } from '@fsd-shared/models/statisticsMonitoring';
import { TGroupSelectorItem } from '@fsd-shared/ui-kit';

export const getCriteriaOptionsList = (
    criteriaGroups?: TCriteriaGroup[],
    withSummary?: boolean,
): TGroupSelectorItem[] => {
    const criteriaDefaultOption = {
        label: <div className={'custom-group-selector__without-group'} />,
        options: [
            {
                label: 'Сводная таблица по всем показателям',
                value: `summary`,
            },
        ],
    };

    const mainList = criteriaGroups
        ? criteriaGroups.map(({ title, criteria, id }) => {
              return {
                  label: (
                      <div className={'custom-group-selector'}>
                          <AppText text={title} textStyle={'DesktopFontBody'} />
                      </div>
                  ),
                  options: criteria.map(({ type, title, id }) => {
                      return { label: title, value: type, key: id };
                  }),
                  key: id,
              };
          })
        : [];

    return withSummary ? [criteriaDefaultOption, ...mainList] : mainList;
};
