import { cookiesDomain } from 'constants/apiMethods';

import { clearNotificationToken } from '@frontend-modules/chat-widget';
import { getDateWithOffsetMs } from 'src-new/utils';

import { getCookie, removeCookie, setCookie } from './cookies';

export const getAccessToken = (): string => <string>getCookie('access_token') || '';
export const getRefreshToken = (): string => <string>getCookie('refresh_token') || '';
export const setAccessToken = (token: string): string | undefined => {
    const tokenData = getDecodedTokenData(token);
    const expires = tokenData.exp ? getDateWithOffsetMs(+tokenData.exp) : 1;

    return setCookie('access_token', token, {
        secure: true,
        expires: expires,
        domain: cookiesDomain,
        sameSite: 'none',
    });
};
export const setRefreshToken = (token: string): string | undefined => {
    const tokenData = getDecodedTokenData(token);
    const expires = tokenData.exp ? getDateWithOffsetMs(+tokenData.exp) : 1;

    return setCookie('refresh_token', token, {
        expires: expires,
        domain: cookiesDomain,
    });
};

export const removeAccessToken = (): void => removeCookie('access_token', { domain: cookiesDomain });
export const removeRefreshToken = (): void => removeCookie('refresh_token', { domain: cookiesDomain });

export const removeAllTokens = (): void => {
    clearNotificationToken();
    removeRefreshToken();
    removeAccessToken();
};

/**
 *
 * @returns user data from token
 */

export const decodeAccessTokenData = (): Record<string, string | number> | undefined => {
    const tokenData = getAccessToken()?.split('.')[1];
    const userData = tokenData ? (JSON.parse(window.atob(tokenData)) as Record<string, string | number>) : undefined;

    return userData;
};
export const getDecodedTokenData = (token: string): Record<string, string | number> => {
    const tokenData = token.split('.')[1];
    return JSON.parse(window.atob(tokenData)) as Record<string, string | number>;
};
