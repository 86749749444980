import React, { useMemo } from 'react';
import { useWindowWidth } from '@frontend-modules/frontend-utils';
import { AppText } from '@frontend-modules/ui-kit';
import { ChartsLegendItem, EmptyData, GoogleChartWrapper, TableBorderless } from '@fsd-shared/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { TQuestionsCreatedCriterionContent } from './QuestionsCreatedContent.types';
import { getParsedCriteriaData } from './QuestionsCreatedContent.utils';

import './QuestionsCreatedContent.style.scss';
export const QuestionsCreatedContent: React.FC<TQuestionsCreatedCriterionContent> = (props) => {
    const { monthList, questionsCreatedDiagramData, isLoading } = props;
    const isTablet = useWindowWidth(1256);

    const { tableColumns, tableData, chartData, chartOptions } = useMemo(
        () => getParsedCriteriaData(monthList, !isTablet, questionsCreatedDiagramData),
        [monthList, questionsCreatedDiagramData],
    );

    const isInvalidData = useMemo(() => {
        if (chartData) {
            const columnsToCheck = [...Array(chartData[0].length - 2)].map((_, index) => {
                return 2 + index;
            });

            return chartData.slice(1).every((row) => {
                return columnsToCheck.every((index) => row[index] === null);
            });
        }
    }, [chartData]);

    return (
        <div className={'adding-questions-content'}>
            {questionsCreatedDiagramData || isLoading ? (
                <>
                    <TableBorderless columns={tableColumns} rows={tableData ?? []} loading={isLoading} />
                    <div className={'adding-questions-content__container'}>
                        <div className={'adding-questions-content__container_legend-months'}>
                            {questionsCreatedDiagramData?.diagram?.data?.map(({ label, info, color }, index) => {
                                const name = info ? `${label} (${info})` : label;

                                return <ChartsLegendItem key={index} color={color} name={name} />;
                            })}
                        </div>

                        <GoogleChartWrapper
                            chartType={'LineChart'}
                            data={chartData}
                            options={chartOptions}
                            isNoData={isInvalidData}
                        />
                    </div>
                </>
            ) : (
                <EmptyData text={'По выбранным месяцам данных не найдено'} />
            )}
        </div>
    );
};
