import { TIME_AFTER_END_OF_LESSON } from 'constants/date';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMaterialsMetaAction, getTrainingProgress } from '@fsd-entities/materials';
import { TrainerTab } from '@fsd-pages/Lesson/TrainerTab';
import { useWindowResize } from '@lms-elements/hooks';
import { checkIsAfter, checkOverdue } from '@lms-elements/utils';
import { getMaterials } from 'api/services/materials';
import { StrokeArrowRightIcon } from 'assets';
import { AttendanceTab } from 'containers/AttendanceTab';
import { OnlineLessonTab } from 'containers/OnlineLessonTab';
import { useRole } from 'hooks';
import { getQuerySearchParams } from 'pages/LessonCard/utils';
import { HardModal } from 'src-new/components/hard-modal';
import {
    useCreateQuestionPopup,
    useGetPopupLessonData,
    useLessonsPopup,
    useSearchQuestionsPopup,
    useStudentTasksViewPopup,
    useSubjectsRedirect,
    useTaskPopup,
} from 'src-new/hooks';
import { AppSkeleton, AppTabs, AppText } from 'src-new/ui';
import { getTabId, getTabLink, getUid, setViewedLesson } from 'src-new/utils';
import { getCourseGroupDetailsAction, getCourseGroupDetailsWithStudentsAction } from 'store/actions/courseGroup';
import { resetLastCheckedAnswerAction } from 'store/actions/material';
import { getStudentsEventRoomsByLessonAndTypeAction } from 'store/actions/proctoring';
import { resetMeetingFilesState } from 'store/reducers/meetingFiles';
import { resetNewSubjectsState } from 'store/reducers/newSubjects';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { EventTypes } from 'types/events';
import { ILessonPopup } from 'types/lessonTask';
import { LessonTypes } from 'types/schedule';

import { addMinutes } from 'utils/date';

import { TTab } from './LessonPopup.types';
import { MaterialsTab } from './MaterialsTab';
import { OnlineLessonTab as TeacherOnlineLessonTab } from './OnlineLessonTab';
import { ProgressTab } from './ProgressTab';
import { StudentTaskTab } from './StudentTaskTab';
import { TeacherTaskTab } from './TeacherTaskTab';

import './style.scss';

/**
 * @description - компонент генерации урока в модалке
 * @param props
 * @constructor
 */
const LessonPopup: FC<ILessonPopup> = (props) => {
    const { urlProps, ...rest } = props;
    const { lessonId, courseGroupId, courseId, taskId, subjectId, tab } = urlProps;
    const { isStudent, isTeacher, isMethodist } = useRole();

    const [tabName, setTabName] = useState<TTab>('materials');
    const [isHaveAdditional, setIsHaveAdditional] = useState(false);
    const [isHaveTrainer, setIsHaveTrainer] = useState(!isStudent);

    const LessonPopup = useLessonsPopup();
    const StudentTasksViewPopup = useStudentTasksViewPopup();
    const SearchQuestionsPopup = useSearchQuestionsPopup();
    const CreateQuestionPopup = useCreateQuestionPopup();
    const TaskPopup = useTaskPopup();

    const dispatch = useDispatch();
    const history = useHistory();
    const { isMobile, isTablet } = useWindowResize();

    const isTeacherMethodist = isTeacher && isMethodist;

    useSubjectsRedirect({
        modalType: 'LESSON',
        urlProps: urlProps,
    });
    const { roles: metaRoles } = useAppSelector((state) => state.newMaterials?.meta) || {};
    const { courseInfo, topicInfo, lessonInfo } = useGetPopupLessonData({
        lessonId,
        courseGroupId,
        courseId,
        taskId,
        isNeedFetch: LessonPopup.isCurrent,
    });

    const { lessonsTree, getLessonsTreeStatus, getTreeByURLStatus, info } = useAppSelector((store) => ({
        lessonsTree: store.newSubject.lessonsTree,
        getLessonsTreeStatus: store.newSubject.getLessonsTreeStatus,
        getTreeByURLStatus: store.newSubject.getTreeByURLStatus,
        info: store.user.info,
    }));

    const handleStudentAvatarClick = useCallback(
        (studentId: number, tab: string) => {
            const searchParams = getQuerySearchParams({
                lessonId: Number(lessonInfo?.id),
                subjectId: courseInfo?.courseId,
                groupId: courseInfo?.id,
            });
            history.push(`/profile/student/${studentId}/${tab}/${searchParams}`);
        },
        [history, courseInfo, lessonInfo],
    );

    const isLoading = getLessonsTreeStatus !== FetchStatus.FETCHED;
    const currentLessonIndex = useMemo(() => lessonsTree.findIndex((item) => item.id === lessonInfo?.id), [lessonInfo]);
    const breadcrumbsHeader = useMemo(() => {
        const courseTitle =
            isTeacher && courseInfo?.title && courseInfo?.courseGroupTitle
                ? `${courseInfo?.title} (${courseInfo?.courseGroupTitle})`
                : courseInfo?.title ?? '';
        return courseInfo?.title && topicInfo?.title ? (
            <div className={'lesson-popup__breadcrumbs'}>
                <AppText text={courseTitle ?? ''} fontStyle={'H4'} />
                <StrokeArrowRightIcon width={4} height={8} />
                <AppText text={topicInfo.title ?? ''} fontStyle={'H4'} />
            </div>
        ) : (
            <></>
        );
    }, [courseInfo, topicInfo]);

    const selectedLessonSchedule = lessonsTree[currentLessonIndex]?.lessonSchedules?.find(
        (schedule) => schedule.type === LessonTypes.ONLINE || schedule.type === LessonTypes.TEST,
    );
    const { isOvered, isStarted } = useMemo(() => {
        if (selectedLessonSchedule) {
            return {
                isOvered: checkOverdue(
                    addMinutes({
                        date: selectedLessonSchedule?.datetimeEnd,
                        minutes: TIME_AFTER_END_OF_LESSON,
                    }),
                ),
                isStarted: checkOverdue(selectedLessonSchedule?.datetimeStart),
            };
        }

        return {
            isOvered: false,
            isStarted: false,
        };
    }, [selectedLessonSchedule]);
    const isSelfPreparation = useMemo(() => {
        return (
            !selectedLessonSchedule &&
            lessonId &&
            lessonsTree[currentLessonIndex]?.lessonSchedules.some(
                (schedule) => schedule.type === LessonTypes.SELFPREPARATION,
            )
        );
    }, [currentLessonIndex, lessonId, selectedLessonSchedule]);
    const tabs = useMemo(() => {
        const materials = {
            label: <AppText text={'Материалы'} fontStyle={'main'} />,
            children: <MaterialsTab type={'main'} lessonId={lessonInfo?.id} />,
            key: '0',
        };
        const additionalMaterials = {
            label: <AppText text={'Доп.Материалы'} fontStyle={'main'} />,
            children: <MaterialsTab type={'additional'} lessonId={lessonInfo?.id} />,
            key: '6',
        };
        const trainer = {
            label: <AppText text={'Тренажёр'} fontStyle={'main'} />,
            children: <TrainerTab lessonId={lessonInfo?.id} courseGroupId={courseGroupId} subjectPageType={'new'} />,
            key: '7',
        };
        const teacherLessons = {
            label: <AppText text={'Онлайн-занятие'} fontStyle={'main'} />,
            children: (
                <TeacherOnlineLessonTab
                    lessonId={+lessonId}
                    lessonScheduleId={selectedLessonSchedule?.id}
                    courseGroupId={+courseGroupId}
                    isOvered={isOvered}
                    isStarted={isStarted}
                    isTest={currentLessonIndex >= 0 ? lessonsTree[currentLessonIndex]?.isTest : false}
                />
            ),
            key: '2',
        };
        const studentLessons = {
            label: <AppText text={'Онлайн-занятие'} fontStyle={'main'} />,
            children: (
                <OnlineLessonTab
                    lessonId={+lessonId}
                    lessonScheduleId={selectedLessonSchedule?.id}
                    isOvered={isOvered}
                    isStarted={isStarted}
                    courseGroupId={+courseGroupId}
                    isTest={currentLessonIndex >= 0 ? lessonsTree[currentLessonIndex]?.isTest : false}
                />
            ),
            key: '2',
        };
        const tasks = {
            label: <AppText text={'Задания'} fontStyle={'main'} />,
            children: isStudent ? (
                <StudentTaskTab
                    lessonId={+lessonId}
                    subjectId={subjectId}
                    courseGroupId={courseInfo?.id}
                    activeTaskId={taskId}
                    courseId={courseId}
                />
            ) : (
                <TeacherTaskTab
                    lesson={Number(lessonId)}
                    subject={subjectId}
                    courseId={courseId}
                    isOvered={isOvered}
                    courseGroupId={courseInfo?.id}
                    onStudentCellClick={(id) => handleStudentAvatarClick(id, 'homeworks')}
                    lessonSchedule={lessonsTree[currentLessonIndex]?.lessonSchedules?.find(
                        (item) => item.type !== 'selfpreparation_lesson',
                    )}
                />
            ),
            key: '3',
        };
        const attendance = {
            label: <AppText text={'Посещаемость'} fontStyle={'main'} />,
            children: (
                <AttendanceTab
                    lessonId={+lessonId}
                    courseGroupId={courseInfo?.id}
                    isOvered={isOvered}
                    isStudent={isStudent}
                    onStudentAvatarClick={(id) => (isStudent ? undefined : handleStudentAvatarClick(id, 'performance'))}
                />
            ),
            key: '4',
        };
        const progress = {
            label: <AppText text={'Прогресс'} fontStyle={'main'} />,
            children: <ProgressTab lessonId={lessonId} courseId={courseGroupId} />,
            key: '5',
        };

        const lessons = isTeacher ? teacherLessons : studentLessons;
        return (courseInfo?.id && courseInfo?.courseId && isStudent) || isTeacher
            ? [
                  materials,
                  ...(isHaveAdditional ? [additionalMaterials] : []),
                  ...(isHaveTrainer ? [trainer] : []),
                  ...(isTeacher ? [progress] : []),
                  ...(!isSelfPreparation && !courseInfo?.isCurious ? [lessons] : []),
                  ...(!courseInfo?.isCurious ? [tasks] : []),
                  ...((courseInfo?.id || (currentLessonIndex >= 0 && lessonsTree[currentLessonIndex]?.isTest)) &&
                  isStarted &&
                  !courseInfo?.isCurious
                      ? [attendance]
                      : []),
              ]
            : [tasks];
    }, [
        isHaveTrainer,
        isSelfPreparation,
        selectedLessonSchedule?.id,
        lessonInfo,
        currentLessonIndex,
        courseInfo,
        isStudent,
        isTeacher,
        lessonId,
        courseGroupId,
        taskId,
        isHaveAdditional,
    ]);

    // Если это учитель-методист и он не зачислен как учитель в курс-группу,
    // то редиректим на аналогичную страницу в старых предметах
    useEffect(() => {
        if (getTreeByURLStatus === FetchStatus.FETCHED && isTeacherMethodist && !lessonInfo) {
            history.push(
                `/subjects/${subjectId ?? courseId}/${lessonId}/${tab ?? 'materials'}?course_group=${courseGroupId}`,
            );
        }
    }, [getTreeByURLStatus, isTeacherMethodist, lessonInfo]);
    // Получаем имя открытой вкладки из url
    useEffect(() => {
        if (tab) {
            // При открытии модалки из другого раздела (напр-р, из успеваемости)
            // В юрлу может попасть имя таба, которое для данного урока недоступно
            // В этом случае отображаем вкладку "Материалы"
            const currentTabId = getTabId(tab);
            const isCurrentTabExist = !!tabs.find((item) => +item.key === currentTabId);
            setTabName(isCurrentTabExist ? tab : 'materials');
        }
    }, [tabs, tab]);
    // При смене вкладки, записываем ее имя в url
    useEffect(() => {
        if (tabName && courseGroupId && lessonInfo?.id) {
            //если переключаемся с вкладки онлайн урока - сбрасываем стейт
            if (tabName !== 'online_lesson') {
                dispatch(resetMeetingFilesState());
            }

            LessonPopup.replaceLastLessonPopup({
                subjectId: subjectId,
                courseGroupId: courseGroupId,
                courseId,
                lessonId: lessonInfo?.id,
                tab: tabName,
            });
        }
    }, [tabName]);

    useEffect(() => {
        if (courseInfo?.id && isTeacher) {
            dispatch(getCourseGroupDetailsWithStudentsAction(courseInfo?.id));
        }
        if (isStudent && courseInfo?.id && courseInfo.courseId) {
            dispatch(getCourseGroupDetailsAction(courseInfo.id));
        }
    }, [courseInfo, isTeacher, isStudent]);

    useEffect(() => {
        if (getTreeByURLStatus === FetchStatus.ERROR) {
            dispatch(resetNewSubjectsState());
            history.push('/new-subjects');
        }
    }, [getTreeByURLStatus]);
    useEffect(() => {
        return () => {
            dispatch(resetMeetingFilesState());
        };
    }, [dispatch]);
    useEffect(() => {
        return () => {
            //чистим стейт, если не на странице предметов
            if (history.location.pathname !== '/new-subjects') {
                dispatch(resetNewSubjectsState());
            }

            dispatch(resetMeetingFilesState());
        };
    }, [dispatch, history]);
    useEffect(() => {
        const lessonSchedules = lessonsTree[currentLessonIndex]?.lessonSchedules ?? [];
        const isTest = lessonsTree[currentLessonIndex]?.isTest;
        const isTestNotPassed =
            lessonSchedules?.length !== 0 &&
            checkIsAfter(new Date().toISOString(), lessonSchedules?.[0]?.datetimeEnd) &&
            isTest;

        if (courseGroupId && (lessonSchedules?.length === 0 || isTestNotPassed)) {
            dispatch(
                getStudentsEventRoomsByLessonAndTypeAction({
                    lesson: lessonId,
                    event_type: EventTypes.TEST,
                    id: info?.id,
                }),
            );
        }
    }, [courseGroupId, dispatch, info?.id, lessonId, lessonsTree, currentLessonIndex]);

    useEffect(() => {
        return () => {
            LessonPopup.closeLastLessonPopup();
            StudentTasksViewPopup.closeLastStudentTasksViewPopup();
            SearchQuestionsPopup.closeLastSearchQuestionsPopup();
            CreateQuestionPopup.closeLastCreateQuestionPopup();
            TaskPopup.closeLastTaskPopup();
        };
    }, []);

    const handleTabClick = (newValue: string) => {
        const link = getTabLink(+newValue);
        setTabName(link);
    };

    const onLessonPaginate = (direction: 'next' | 'prev') => {
        const newIndex = direction === 'next' ? currentLessonIndex + 1 : currentLessonIndex - 1;
        if (currentLessonIndex >= 0 && newIndex >= 0 && newIndex < lessonsTree.length) {
            const lesson = {
                id: lessonsTree[newIndex].id,
                title: lessonsTree[newIndex].title,
            };

            //заменяем модалку
            LessonPopup.replaceLastLessonPopup({
                subjectId: subjectId,
                courseGroupId: courseInfo?.id || 0,
                courseId,
                lessonId: lesson?.id,
            });

            // Для любознательного запоминаем просмотренный урок
            // ТОЛЬКО ДЛЯ СТУДЕНТА
            if (isStudent && courseInfo?.isCurious && topicInfo && lessonInfo) {
                const itemToAdd = {
                    course: courseInfo,
                    topic: topicInfo.id,
                    lesson: lessonInfo.id,
                };
                setViewedLesson(itemToAdd);
            }
            dispatch(resetLastCheckedAnswerAction());
        }
    };

    const checkAdditionalMaterials = (id?: number) => {
        if (id) {
            void getMaterials(id, 'additional').then((res) => {
                setIsHaveAdditional(res?.length > 0);
            });
        }
    };
    const checkTrainer = (lessonId?: number, courseGroupId?: number) => {
        if (lessonId && courseGroupId) {
            void getTrainingProgress({ lesson: lessonId, courseGroup: courseGroupId }).then((res) => {
                setIsHaveTrainer(!!res?.question);
            });
        }
    };

    useEffect(() => {
        setIsHaveAdditional(false);
        checkAdditionalMaterials(lessonInfo?.id);
    }, [lessonInfo?.id]);

    useEffect(() => {
        if (metaRoles?.isStudent) {
            setIsHaveTrainer(false);
            checkTrainer(lessonInfo?.id, courseGroupId);
        }
    }, [lessonInfo?.id, courseGroupId, metaRoles]);

    useEffect(() => {
        if (courseInfo?.courseId) {
            dispatch(getMaterialsMetaAction(courseInfo.courseId));
        }
    }, [courseInfo]);

    return (
        <HardModal
            className={'lesson-popup'}
            size={'large'}
            placement={'left'}
            getContainer={false}
            title={breadcrumbsHeader}
            customWrapperStyle={{
                width: '100%',
                maxWidth: isTablet ? '100%' : '70%',
                minWidth: isTablet ? 'unset' : '900px',
            }}
            autoFocus={false}
            {...rest}
        >
            {isLoading ? (
                <div className={'lesson-popup__container-skeleton'}>
                    <AppSkeleton key={getUid()} width={'100%'} height={30} />
                    <AppSkeleton key={getUid()} height={'100%'} width={'100%'} />
                </div>
            ) : (
                <div className={'lesson-popup__container'}>
                    <div className={'lesson-popup__container_lessons-paginate'}>
                        <div
                            className={`btn-prev btn ${currentLessonIndex <= 0 ? 'disabled' : ''}`}
                            onClick={() => onLessonPaginate('prev')}
                        >
                            <StrokeArrowRightIcon height={14} width={7} />
                        </div>
                        <div className={'lesson-popup__container_lessons-paginate-current-lesson'}>
                            <AppText text={lessonInfo?.title ?? ''} fontStyle={'H2'} />
                        </div>

                        <div
                            className={`btn-next btn ${
                                currentLessonIndex === lessonsTree.length - 1 ? 'disabled' : ''
                            }`}
                            onClick={() => onLessonPaginate('next')}
                        >
                            <StrokeArrowRightIcon height={14} width={7} />
                        </div>
                    </div>
                    <AppTabs
                        key={`${tabName}-${lessonId}`}
                        tabsList={tabs}
                        onTabClick={handleTabClick}
                        activeTabKey={getTabId(tabName, true).toString()}
                        centered={!isMobile}
                    />
                </div>
            )}
        </HardModal>
    );
};

export default LessonPopup;
