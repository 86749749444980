import React from 'react';
import { Chart } from 'react-google-charts';
import { AppText } from '@frontend-modules/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { baseChartOptions } from './GoogleChartWrapper.config';
import { TGoogleChartWrapperProps } from './GoogleChartWrapper.types';

import './GoogleChartWrapper.style.scss';
export const GoogleChartWrapper: React.FC<TGoogleChartWrapperProps> = (props) => {
    const {
        isNoData = false,
        emptyDataText = 'Данные отсутствуют',
        width = '100%',
        height = 400,
        options,
        ...rest
    } = props;

    const mergedChartOptions = {
        ...baseChartOptions,
        ...options,
        hAxis: { ...baseChartOptions.hAxis, ...options?.hAxis },
        vAxis: { ...baseChartOptions.vAxis, ...options?.vAxis },
        tooltip: { ...baseChartOptions.tooltip, ...options?.tooltip },
    };

    return (
        <div className={'google-chart-wrapper'}>
            {isNoData ? (
                <AppText text={emptyDataText} textStyle={'DesktopH3'} color={E_COLORS.light_grey_color_2} />
            ) : (
                // @ts-expect-error Server Component
                <Chart width={width} height={height} options={mergedChartOptions} {...rest} />
            )}
        </div>
    );
};
