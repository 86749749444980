import React, { useEffect } from 'react';
import { ChatWidget } from '@frontend-modules/chat-widget';
import * as Sentry from '@sentry/react';
import { TARIFF_CURIOUS_TITLE } from 'App/constants';
import { useCheckPackageVersion } from 'hooks';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

export const WithChatwoot: React.FC = ({ children }) => {
    const { userInfo, userInfofetchStatus, logoutStatus } = useAppSelector((store) => ({
        userInfo: store.user.info,
        userInfofetchStatus: store.user.fetchStatus,
        logoutStatus: store.auth.logoutFetchStatus,
    }));

    useCheckPackageVersion();

    useEffect(() => {
        if (userInfofetchStatus === FetchStatus.FETCHED && userInfo && !(window as { Cypress?: boolean }).Cypress) {
            const { id, email, firstName, lastName, patronymic, photoUrl } = userInfo;
            window.$chatwoot?.setUser(id, {
                name: `${firstName} ${lastName} ${patronymic}`,
                email: email || '',
                avatar_url: photoUrl,
            });

            Sentry.setUser({
                id: String(userInfo.id),
                email: userInfo.email,
                username: `${userInfo?.lastName ?? ''} ${userInfo?.firstName ?? ''}`,
            });
        }

        if (
            !userInfo &&
            logoutStatus === FetchStatus.FETCHED &&
            ((window as unknown) as { $chatwoot?: { reset: () => void } }).$chatwoot &&
            !(window as { Cypress?: boolean }).Cypress
        ) {
            ((window as unknown) as { $chatwoot?: { reset: () => void } }).$chatwoot?.reset();

            Sentry.configureScope((scope) => scope.setUser(null));
        }
    }, [userInfofetchStatus, userInfo, logoutStatus]);

    const isChatWidgetAvailable = userInfo?.tariffTitle !== TARIFF_CURIOUS_TITLE;

    return (
        <>
            {userInfo && isChatWidgetAvailable && (
                <ChatWidget
                    iconPlacement={{ position: 'absolute', right: 100, bottom: 16 }}
                    styles={{ zIndex: 101 }}
                    chatType={'chat'}
                    userId={String(userInfo.externalId)}
                />
            )}
            {children}
        </>
    );
};
