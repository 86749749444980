import React, { FC, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { AppButton, AppModal } from '@frontend-modules/ui-kit';

import { INotAllowedModalProps } from './NotAllowedModal.types';

import './NotAllowedModal.styles.scss';
const CnReAuthModal = cn('re-auth-modal');

/**
 */
const NotAllowedModal: FC<INotAllowedModalProps> = (props) => {
    const { onLogout, isShowModal, setIsShowModal } = props;

    const onCloseModal = () => {
        onLogout();
        setIsShowModal(false);
    };

    useEffect(() => {
        return () => onLogout();
    }, []);

    return (
        <AppModal
            style={{ marginTop: 200 }}
            isShow={isShowModal}
            setShow={onCloseModal}
            title={'Недостаточно прав'}
            description={'У текущего аккаунта недостаточно прав для использования сервиса'}
            className={CnReAuthModal()}
            footer={<AppButton type={'primary'} label={'Сменить аккаунт'} onClick={onCloseModal} />}
        />
    );
};

export default NotAllowedModal;
