import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    addQuestionsBlock,
    addSingleQuestion,
    addTask,
    deleteQuestionsBlock,
    deleteTask,
    TNormalizedQuestion,
} from 'src-new/components/lesson-task';
import {
    addCreatedQuestionAction,
    createEmptyTaskQuestionAction,
    createLessonTaskAction,
    deleteBlockAction,
    deleteTaskQuestionAction,
    getAssignedTasksAction,
    getLessonDataAction,
    getQuestionDetailsAction,
    getQuestionsBySearchAction,
    getSubjectDataByIdAction,
    getSubjectsDataAction,
    getTaskDataAction,
    setOpenedQuestionIdAction,
    setQuestionsStateAction,
    setSelectedBlockAction,
    updateLessonTaskAction,
} from 'store/actions/lessonTask';
import { FetchStatus } from 'types/api';
import {
    IAssignmentItem,
    ITaskData,
    LessonDetails,
    QueryParams,
    TAssignedQuestion,
    TAssignedQuestions,
    TCodifierSubjectData,
    TResponseQuestionDetailed,
    TSearchBlock,
    TSearchResults,
    TSelectedBlock,
    TSingleQuestion,
} from 'types/lessonTask';

export interface LessonTaskState {
    getStatusLessons: FetchStatus;
    getStatusQuestion: FetchStatus;
    getQuestionsBySearchStatus: FetchStatus;
    getAssignedTasksListStatus: FetchStatus;
    createLessonTaskStatus: FetchStatus;
    updateLessonTaskStatus: FetchStatus;
    searchTreeStatus: FetchStatus;
    taskData: IAssignmentItem | undefined;
    newTaskData: ITaskData;
    questionsList: TNormalizedQuestion[];
    assignedTasksList: TAssignedQuestions | undefined;
    assignedTasksListInitial: TAssignedQuestions | undefined;
    assignedTaskListLastVersions: TAssignedQuestions | undefined;
    searchResults: TSearchResults | undefined;
    selectedBlock: TSelectedBlock | undefined;
    filtersData: QueryParams | undefined;
    subjectsTree: TCodifierSubjectData[];
    lessonDetails: {
        [lessonId: number]: LessonDetails;
    };
    openedQuestionId: number | undefined;
    isSearchQuestionsIsAllowed: boolean;
    questionDetails: TResponseQuestionDetailed | undefined;
    error: unknown;
}

export const initialState: LessonTaskState = {
    getStatusLessons: FetchStatus.INITIAL,
    getStatusQuestion: FetchStatus.INITIAL,
    getQuestionsBySearchStatus: FetchStatus.INITIAL,
    getAssignedTasksListStatus: FetchStatus.INITIAL,
    createLessonTaskStatus: FetchStatus.INITIAL,
    updateLessonTaskStatus: FetchStatus.INITIAL,
    searchTreeStatus: FetchStatus.INITIAL,
    lessonDetails: {},
    assignedTasksList: undefined,
    assignedTasksListInitial: undefined,
    assignedTaskListLastVersions: undefined,
    filtersData: undefined,
    selectedBlock: undefined,
    searchResults: undefined,
    subjectsTree: [],
    taskData: undefined,
    newTaskData: {
        title: '',
        description: '',
        isTemplate: false,
        selectedTypeId: 0,
    },
    questionsList: [],
    isSearchQuestionsIsAllowed: false,
    questionDetails: undefined,
    openedQuestionId: undefined,
    error: null,
};

const lessonTaskSlice = createSlice<LessonTaskState, SliceCaseReducers<LessonTaskState>>({
    name: 'LessonTask',
    initialState,
    reducers: {
        resetOpenedQuestionState(state) {
            state.questionDetails = undefined;
            state.openedQuestionId = undefined;
        },
        resetQuestionsState(state) {
            state.searchResults = undefined;
            state.assignedTasksList = undefined;
            state.taskData = undefined;
            state.filtersData = undefined;
            state.isSearchQuestionsIsAllowed = false;
            state.assignedTaskListLastVersions = undefined;
            state.getAssignedTasksListStatus = FetchStatus.INITIAL;
            state.getStatusLessons = FetchStatus.INITIAL;
            state.createLessonTaskStatus = FetchStatus.INITIAL;
            state.updateLessonTaskStatus = FetchStatus.INITIAL;
        },
        resetToLastVersion(state) {
            state.filtersData = undefined;
            state.assignedTasksList = state.assignedTaskListLastVersions;
            state.searchResults = undefined;
        },
        setLastVersion(state) {
            state.filtersData = undefined;
            state.assignedTaskListLastVersions = state.assignedTasksList;
        },
        setNewTaskData(state, { payload }) {
            state.newTaskData = payload
                ? {
                      ...state.newTaskData,
                      ...payload,
                  }
                : {
                      title: '',
                      description: '',
                      isTemplate: false,
                      selectedTypeId: 0,
                  };
        },
        setQuestionsList(state, { payload }) {
            state.questionsList = payload;
        },
        setSearchQuestionsIsAllowed(state) {
            state.isSearchQuestionsIsAllowed = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setOpenedQuestionIdAction, (state, { payload }) => {
            state.openedQuestionId = payload;
        });
        builder.addCase(deleteTaskQuestionAction, (state, { payload }) => {
            state.assignedTasksList = deleteTask(payload, state.assignedTasksList);
            state.assignedTaskListLastVersions = state.assignedTasksList;
        });
        builder.addCase(createEmptyTaskQuestionAction, (state, { payload }) => {
            const { questionsNum, saveIntoLastVersion = false } = payload;
            state.assignedTasksList = addTask({
                position: questionsNum,
                task: {} as TAssignedQuestion,
                currentState: state.assignedTasksList,
            });
            if (saveIntoLastVersion) {
                state.assignedTaskListLastVersions = state.assignedTasksList;
            }
        });
        builder.addCase(addCreatedQuestionAction, (state, { payload }) => {
            const { question, questionNum, assignment } = payload;

            const filtersObj = {
                id: question.id,
                assignment: assignment,
                questionNumber: questionNum,
                single: true,
                quantity: 1,
                filters: {
                    id: question.id,
                },
            };
            state.assignedTasksList = addSingleQuestion({
                position: questionNum,
                question: { ...filtersObj, questionContent: question } as TSingleQuestion,
                currentState: state.assignedTasksList,
            });
        });
        builder.addCase(setQuestionsStateAction, (state, { payload }) => {
            const { left, right, questionNum } = payload;

            if (right) {
                state.assignedTasksList = addTask({
                    position: questionNum,
                    task: right,
                    currentState: state.assignedTasksList,
                });
            }
            if (left) {
                state.searchResults = {
                    ...state.searchResults,
                    [questionNum]: left.searchBlocksList,
                };
            }

            //Если во время изменения стейта активен для редактирования какой-либо блок
            if (state.selectedBlock) {
                const { side, number, block } = state.selectedBlock;

                const targetBlock =
                    side === 'left'
                        ? state.searchResults?.[questionNum].find(({ id }) => id === block.id)
                        : state.assignedTasksList?.[number + 1].searchBlocksList.find(({ id }) => id === block.id);

                if (targetBlock) {
                    state.selectedBlock = {
                        ...state.selectedBlock,
                        block: targetBlock,
                    };
                }
            }
        });
        builder.addCase(setSelectedBlockAction, (state, { payload }) => {
            state.selectedBlock = payload;
        });
        builder.addCase(deleteBlockAction, (state, { payload }) => {
            const { questionNum, selectedBlock } = payload;
            const { side, number } = selectedBlock;

            if (side === 'left' && state.searchResults?.[questionNum]) {
                state.searchResults = {
                    ...state.searchResults,
                    [questionNum]: [
                        ...state.searchResults?.[questionNum]?.slice(0, number - 1),
                        ...state.searchResults?.[questionNum]?.slice(number),
                    ],
                };
            } else if (state.assignedTasksList?.[questionNum]) {
                state.assignedTasksList = deleteQuestionsBlock({
                    position: questionNum,
                    blockPosition: number,
                    currentState: state.assignedTasksList,
                });
            }
        });
        builder
            .addCase(getQuestionDetailsAction.pending, (state) => {
                state.getStatusQuestion = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getQuestionDetailsAction.fulfilled, (state, { payload }) => {
                state.getStatusQuestion = FetchStatus.FETCHED;
                state.questionDetails = payload;
                state.error = null;
            })
            .addCase(getQuestionDetailsAction.rejected, (state, { error }) => {
                state.getStatusQuestion = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getLessonDataAction.pending, (state) => {
                state.getStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getLessonDataAction.fulfilled, (state, { payload }) => {
                state.getStatusLessons = FetchStatus.FETCHED;
                state.lessonDetails[payload.id] = payload;
                state.error = null;
            })
            .addCase(getLessonDataAction.rejected, (state, { error }) => {
                state.getStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getTaskDataAction.pending, (state) => {
                state.getStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getTaskDataAction.fulfilled, (state, { payload }) => {
                state.getStatusLessons = FetchStatus.FETCHED;
                state.taskData = payload;
                state.error = null;
            })
            .addCase(getTaskDataAction.rejected, (state, { error }) => {
                state.getStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getAssignedTasksAction.pending, (state) => {
                state.getAssignedTasksListStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAssignedTasksAction.fulfilled, (state, { payload }) => {
                state.getAssignedTasksListStatus = FetchStatus.FETCHED;
                state.assignedTasksList = payload;
                state.assignedTasksListInitial = payload;
                state.assignedTaskListLastVersions = payload;
                state.error = null;
            })
            .addCase(getAssignedTasksAction.rejected, (state, { error }) => {
                state.getAssignedTasksListStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getQuestionsBySearchAction.pending, (state) => {
                state.getQuestionsBySearchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getQuestionsBySearchAction.fulfilled, (state, { payload }) => {
                const { questionsList, filters, assignment, questionNum, selectedBlock } = payload;
                state.getQuestionsBySearchStatus = FetchStatus.FETCHED;
                state.filtersData = filters;
                const filtersObj = {
                    assignment: assignment,
                    questionNumber: questionNum,
                    single: false,
                    quantity: questionsList.length,
                    filters: {
                        ...filters,
                    },
                };

                const res: TSearchBlock = {
                    ...filtersObj,
                    questionsContentList: questionsList.map((item) => {
                        return {
                            ...filtersObj,
                            questionContent: item,
                        };
                    }),
                };
                if (selectedBlock) {
                    const { side, number, block } = selectedBlock;

                    if (side === 'left' && state.searchResults?.[questionNum]) {
                        state.searchResults = {
                            ...state.searchResults,
                            [questionNum]: [
                                ...state.searchResults[questionNum].slice(0, number - 1),
                                res,
                                ...state.searchResults[questionNum].slice(number),
                            ],
                        };
                    } else if (state.assignedTasksList?.[questionNum]) {
                        state.assignedTasksList = addQuestionsBlock({
                            position: questionNum,
                            blockPosition: number,
                            block: { ...res, id: block.id },
                            currentState: state.assignedTasksList,
                        });
                    }
                } else {
                    state.searchResults = {
                        ...state.searchResults,
                        [questionNum]: state.searchResults?.[questionNum]
                            ? [...state.searchResults?.[questionNum], res]
                            : [res],
                    };
                }

                state.error = null;
            })
            .addCase(getQuestionsBySearchAction.rejected, (state, { error }) => {
                state.getQuestionsBySearchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(createLessonTaskAction.pending, (state) => {
                state.createLessonTaskStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createLessonTaskAction.fulfilled, (state, { payload }) => {
                state.createLessonTaskStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(createLessonTaskAction.rejected, (state, { error }) => {
                state.createLessonTaskStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(updateLessonTaskAction.pending, (state) => {
                state.updateLessonTaskStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(updateLessonTaskAction.fulfilled, (state, { payload }) => {
                state.updateLessonTaskStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(updateLessonTaskAction.rejected, (state, { error }) => {
                state.updateLessonTaskStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getSubjectsDataAction.pending, (state) => {
                state.searchTreeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getSubjectsDataAction.fulfilled, (state, { payload }) => {
                state.searchTreeStatus = FetchStatus.FETCHED;
                state.subjectsTree = payload.map((item) => {
                    return {
                        id: item.id,
                        title: item.title,
                    };
                });
                state.error = null;
            })
            .addCase(getSubjectsDataAction.rejected, (state, { error }) => {
                state.searchTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getSubjectDataByIdAction.pending, (state) => {
                state.searchTreeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getSubjectDataByIdAction.fulfilled, (state, { payload }) => {
                const { data, id } = payload;
                state.searchTreeStatus = FetchStatus.FETCHED;

                const newTree = state.subjectsTree.length
                    ? state.subjectsTree.map((item) => {
                          if (+id === +item.id) {
                              return { ...data[0] };
                          }
                          return { ...item };
                      })
                    : data;

                state.subjectsTree = [...newTree];
                state.error = null;
            })
            .addCase(getSubjectDataByIdAction.rejected, (state, { error }) => {
                state.searchTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});
export const lessonTaskReducer = lessonTaskSlice.reducer;
export const resetOpenedQuestionState = lessonTaskSlice.actions.resetOpenedQuestionState as ActionCreatorWithoutPayload;
export const resetQuestionsState = lessonTaskSlice.actions.resetQuestionsState as ActionCreatorWithoutPayload;
export const resetToLastVersionState = lessonTaskSlice.actions.resetToLastVersion as ActionCreatorWithoutPayload;
export const setLastVersionState = lessonTaskSlice.actions.setLastVersion as ActionCreatorWithoutPayload;
export const setSearchQuestionsIsAllowed = lessonTaskSlice.actions
    .setSearchQuestionsIsAllowed as ActionCreatorWithoutPayload;

export const { setNewTaskData, setQuestionsList } = lessonTaskSlice.actions;
