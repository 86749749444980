import { isEmptyList } from '@frontend-modules/frontend-utils';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    createLessonTask,
    createLessonTaskQuestion,
    deleteLessonTaskQuestion,
    getAssignedTasks,
    getLessonDetails,
    getQuestionDetails,
    getQuestionsByQuery,
    getSubjectDataById,
    getSubjectsData,
    getTaskData,
    updateLessonTask,
    updateLessonTaskQuestion,
} from 'api/services/lessonTask';
import { Status } from 'pages/QuestionBankPage/types';
import { getGroupedTasks, getMappedQuestions, normalizeQuestionsOrder } from 'src-new/components/lesson-task';
import { getUpdatedQuestions } from 'src-new/components/lesson-task/utils';
import {
    TAssignedQuestions,
    TCreateTaskProps,
    TQetQuestionsBySearchProps,
    TResponseQuestion,
    TSelectedBlock,
    TSetQuestionsStateProps,
    TUpdateTaskProps,
} from 'types/lessonTask';

import { createUrlQuery } from 'utils/createUrlQuery';

export const getQuestionDetailsAction = createAsyncThunk(
    'lessonTask/getQuestionDetails',
    async (questionId: number) => await getQuestionDetails(questionId),
);
export const getLessonDataAction = createAsyncThunk(
    'lessonTask/getLessonData',
    async (lessonId: number) => await getLessonDetails(lessonId),
);
export const getTaskDataAction = createAsyncThunk('lessonTask/getTaskData', async (id: number) => {
    return await getTaskData(id);
});

export const updateLessonTaskAction = createAsyncThunk(
    'lessonTask/updateLessonTask',
    async (params: TUpdateTaskProps) => {
        const { taskData, assignedQuestions, assignedTasksListInitial } = params;

        return await updateLessonTask(taskData).then(async (assignment) => {
            const mappedInitialList = assignedTasksListInitial ? getMappedQuestions(assignedTasksListInitial) : [];
            const mappedHandlingQuestionsList = assignedQuestions
                ? normalizeQuestionsOrder(getMappedQuestions(assignedQuestions))
                : [];

            const updatedQuestions = getUpdatedQuestions(mappedHandlingQuestionsList, mappedInitialList);
            const createdQuestions = mappedHandlingQuestionsList.filter(
                ({ id }) => !mappedInitialList.find((item) => item.id === id),
            );
            const deletedQuestionsIds = mappedInitialList
                .map((old) => {
                    const sameItemInNew = mappedHandlingQuestionsList.find(
                        (item) => item.id === old.id && item.questionNumber === old.questionNumber,
                    );
                    const sameItemInUpdate = updatedQuestions.find(({ id, questionNumber }) => id === old.id);
                    const isDeleted = !sameItemInNew && !sameItemInUpdate;

                    if (isDeleted) {
                        return old.id;
                    }
                })
                .filter((id) => !!id);

            const updatedAssignment = updatedQuestions.length
                ? //@ts-ignore
                  await updateLessonTaskQuestion(updatedQuestions)
                : undefined;
            const deletedAssignment = deletedQuestionsIds.length
                ? //@ts-ignore
                  await deleteLessonTaskQuestion(deletedQuestionsIds)
                : undefined;
            const createdAssignment = createdQuestions.length
                ? await createLessonTaskQuestion(createdQuestions)
                : undefined;

            return {
                assignment,
                updatedAssignment: isEmptyList(updatedAssignment) ? undefined : updatedAssignment,
                deletedAssignment,
                createdAssignment,
            };
        });
    },
);
export const createLessonTaskAction = createAsyncThunk(
    'lessonTask/createLessonTask',
    async (params: TCreateTaskProps) => {
        const { taskData, assignedQuestions } = params;

        return await createLessonTask(taskData).then(async (assignment) => {
            const mappedHandlingQuestionsList = getMappedQuestions(assignedQuestions);

            const createdQuestions = mappedHandlingQuestionsList.map((item) => {
                return { ...item, assignment: assignment.id };
            });

            const createdAssignment = createdQuestions.length
                ? await createLessonTaskQuestion(normalizeQuestionsOrder(createdQuestions))
                : undefined;

            return {
                assignment,
                createdAssignment,
            };
        });
    },
);

export const getAssignedTasksAction = createAsyncThunk('lessonTask/getAssignedTasks', async (id?: number) => {
    //получаем все вопросы в упражнении
    const taskData = await getAssignedTasks(id);

    // Получаем контент всех одиночных вопросов в упражнении
    const singleQuestionsIds = taskData.map((question) => question.filters.id ?? 0).filter((item) => !!item);
    const singleQuery = createUrlQuery({ include_ids: singleQuestionsIds });
    const allSingleQuestionsList = singleQuestionsIds.length ? await getQuestionsByQuery(singleQuery) : [];

    //Группируем вопросы по заданиям и добавляем сами вопросы
    return await getGroupedTasks(taskData, allSingleQuestionsList);
});

export const getQuestionsBySearchAction = createAsyncThunk(
    'lessonTask/getQuestionsBySearch',
    async (props: TQetQuestionsBySearchProps) => {
        const { data, assignment, questionNum, selectedBlock } = props;
        const { skills_or, skills_and, answers_type, topics, difficulty, text } = data;
        const url = createUrlQuery(data);
        const questionsList = await getQuestionsByQuery(url);
        // @ts-ignore
        const excludedQuestions: number[] = questionsList
            .map((item) => {
                if (selectedBlock?.block?.filters?.excludeIds?.includes(item.id)) {
                    return item.id;
                }
            })
            .filter(Boolean);

        return {
            assignment,
            questionNum,
            filters: {
                text,
                difficulty,
                topics,
                skillsOr: skills_or,
                skillsAnd: skills_and,
                answersType: answers_type,
                excludeIds: excludedQuestions ?? [],
            },
            questionsList,
            selectedBlock,
        };
    },
);

export const getSubjectsDataAction = createAsyncThunk('lessonTask/getSubjectsData', async () => {
    return await getSubjectsData();
});
export const getSubjectDataByIdAction = createAsyncThunk('lessonTask/getSubjectDataById', async (id: number) => {
    return {
        data: await getSubjectDataById(id),
        id: id,
    };
});
export const setOpenedQuestionIdAction = createAction('lessonTask/setOpenedQuestionId', (id: number) => {
    return {
        payload: id,
    };
});
export const setQuestionsStateAction = createAction('lessonTask/setSearchBlock', (item: TSetQuestionsStateProps) => {
    return {
        payload: item,
    };
});
export const setSelectedBlockAction = createAction('lessonTask/setSelectedBlock', (item?: TSelectedBlock) => {
    return {
        payload: item,
    };
});
export const deleteBlockAction = createAction(
    'lessonTask/deleteBlock',
    (params: { questionNum: number; selectedBlock: TSelectedBlock }) => {
        return {
            payload: params,
        };
    },
);
export const deleteTaskQuestionAction = createAction('lessonTask/deleteTaskQuestion', (questionsNum: number) => {
    return {
        payload: questionsNum,
    };
});
export const createEmptyTaskQuestionAction = createAction(
    'lessonTask/createEmptyTaskQuestion',
    (params: { questionsNum: number; saveIntoLastVersion?: boolean }) => {
        return {
            payload: params,
        };
    },
);
export const addCreatedQuestionAction = createAction(
    'lessonTask/addCreatedQuestion',
    (props: { question: TResponseQuestion; questionNum: number; assignment?: number }) => {
        return {
            payload: props,
        };
    },
);
